/**
 * aixios工具函数
 * */
import axios from "axios"
import qs from "qs"
// import router from "../router";
// 设置请求服务器api地址和超时时间
axios.defaults.baseURL = "http://maplays.net:9000"
// axios.defaults.baseURL = "http://192.168.9.6:8751"
// axios.defaults.baseURL = "http://192.168.9.107:8751"
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(config => {
    // 每次请求 都统一带上令牌
    const myToken = window.localStorage.getItem('token');
    if (myToken) {
        config.headers['Authorization'] = 'Bearer ' + myToken
    }
    return config;
})

// 响应拦截器
axios.interceptors.response.use(res => {
    return res
}, err => {
    // 判断是否token过期token过期跳转道登录界面，清楚之前的token与人员信息
    if (err.response && err.response.status === 401){
        window.localStorage.removeItem("token")
        window.localStorage.removeItem("userInfo")
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
    }
    Promise.reject(err)
})

// 导出一个对象 包含get方式和post方式
export default {
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, qs.stringify(params))
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    postBody(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    uploadPost(url, params){
        return new Promise((resolve, reject) => {
            axios.post(url, params,{headers : {'Content-type' : 'multipart/form-data'}})
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}
